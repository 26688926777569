.under-construction {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--background-color);
  color: var(--highlight-blue);
  text-align: center;
  background-color: rgb(29, 32, 32);
}

.under-construction h1 {
  font-size: 48px;
  margin-bottom: 20px;
  color: var(--highlight-blue);
}

.under-construction p {
  font-size: 24px;
  color: var(--text-color);
}
