.linkedin-button {
  background-color: transparent;
  color: white;
  border: 1px solid #047069;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  transition: background-color 0.3s, color 0.3s;
  text-decoration: none;
}

.linkedin-button:hover {
  background-color: #04706990;
  color: white;
}

.linkedin-icon {
  margin-right: 8px;
}

a {
  text-decoration: none;
}
