.language-selector-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  position: absolute;
  top: 20px;
  right: 20px;
  gap: 10px;
}

.language-selector-container button {
  width: 50px;
  height: 40px;
  border-radius: 10px;
  font-weight: bold;
  color: white;
  font-family: "Ubuntu", sans-serif;
  background-color: transparent;
  border: 1px solid rgba(0, 192, 179, 0.3);
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.language-selector-container button:hover {
  background-color: #04706990;
  color: white;
}

.language-selector-container button.selected {
  background-color: rgb(0, 192, 179);
  color: white;
}
