.glass-morphism {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  box-shadow: 0 4px 30px rgba(0, 0, 255, 0.1); /* Slight blue shadow */
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  padding: 20px;
  margin: 20px 0;
  color: white;
  text-align: center;
  letter-spacing: 1px; /* Letter spacing */
  line-height: 1.6; /* Line separation */
  width: 50%;
  animation: shadowAnimation 10s infinite alternate;
}

/* @keyframes shadowAnimation {
  0% {
    box-shadow: 0 4px 30px rgba(0, 0, 255, 0.1);
  }
  50% {
    box-shadow: 0 4px 30px rgba(0, 0, 255, 0.3);
  }
  100% {
    box-shadow: 0 4px 30px rgba(0, 0, 255, 0.1);
  }
} */

@keyframes shadowAnimation {
  0% {
    box-shadow: 0 4px 30px rgba(0, 198, 255, 0.1);
  }
  25% {
    box-shadow: 0 4px 30px rgba(0, 114, 255, 0.3);
  }
  50% {
    box-shadow: 0 4px 30px rgba(0, 198, 255, 0.4);
  }
  75% {
    box-shadow: 0 4px 30px rgba(0, 114, 255, 0.3);
  }
  100% {
    box-shadow: 0 4px 30px rgba(0, 198, 255, 0.1);
  }
}

p {
  margin: 0;
  padding: 0;
}
