@import url("https://fonts.googleapis.com/css2?family=Ubuntu&display=swap");

.animated-background {
  background: radial-gradient(circle, #26303d, #1e3a8a, #0f172a);
  background-size: 800% 800%;
  animation: gradientAnimation 25s ease-in-out infinite; /* Smooth radial animation */
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

h1 {
  color: white;
  font-family: "Ubuntu", sans-serif;
  font-size: 60px;
  letter-spacing: 2px;
  padding: 0px;
  margin: 0px;
}

h2 {
  color: white;
  font-family: "Ubuntu", sans-serif;
  font-size: 40px;
  letter-spacing: 2px;
  padding: 0px;
  margin: 0px;
  color: #047069;
}

@keyframes gradientAnimation {
  0% {
    background-position: 50% 50%;
  }
  25% {
    background-position: 60% 40%;
  }
  50% {
    background-position: 40% 60%;
  }
  75% {
    background-position: 70% 30%;
  }
  100% {
    background-position: 50% 50%;
  }
}
